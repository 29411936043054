<div class="grid grid-cols-1 sm:grid-cols-2 h-full">
  <!-- Left -->
  <div
    #pokemonViewRef
    class="sidebar flex flex-col flex-1 grid-cols-1 {{
      this.pokemonSpecies?.color?.name || 'default'
    }}-gradient min-h-screen"
  >
    <!-- Left top bar -->
    <div
      class="
        grid grid-cols-3
        place-items-center
        p-2
        bg-gray-500/30
        h-min
        max-h-screen
        text-white
      "
    >
      <!-- Left -->
      <div
        matTooltip="{{ 'egg-label' | translate }}"
        class="
          font-bold
          m-auto
          text-white text-center
          align-middle
          justify-start
          p-1
          rounded-md
          text-lg
          uppercase
          break-words
        "
      >
        <span class="fa-solid fa-egg align-middle">&nbsp;</span>
        <span class="text-xs align-middle">
          {{ this.eggGroups.join(", ") || "?" }}
        </span>
      </div>
      <!-- Middle -->
      <span
        class="
          whitespace-nowrap
          self-center
          flex flex-wrap
          m-auto
          justify-center
          text-lg
          font-bold
          gap-2
        "
      >
        <span>
          {{ pokedex.identifierToReadableName(this.pokemonName) }}
        </span>
        <div class="flex gap-2 self-center justify-center align-middle">
          <app-poketype
            *ngFor="let TName of this.pokemon?.types"
            pokemonType="{{ TName.type.name }}"
          ></app-poketype>
        </div>
      </span>
      <!-- Right -->
      <div class="flex flex-wrap gap-4 justify-end align-middle">
        <div
          class="text-lg text-center"
          matTooltip="{{ 'weight' | translate }}"
        >
          <span class="fa-solid fa-weight-hanging text-white"></span>
          <span class="text-white text-bold">
            {{ this.displayWeight }}
          </span>
        </div>

        <div
          class="text-lg text-center self-center"
          matTooltip="{{ 'height' | translate }}"
        >
          <span class="fa-solid fa-ruler text-white"></span>
          <span class="text-white text-bold">
            {{ this.displayHeight }}
          </span>
        </div>
      </div>
    </div>
    <!-- Pokemon preview -->
    <div class="flex-1 flex justify-center align-middle">
      <img
        class="object-contain p-4 pixelated w-full h-auto"
        src="{{
          this.pokemon
            ? this.pokemon.sprites.front_default || '/assets/img/MISSINGNO.webp'
            : '/assets/img/MISSINGNO.webp'
        }}"
        alt="Photo
        of a {{ pokedex.identifierToReadableName(this.pokemonName) }}"
      />
    </div>
  </div>
  <!-- Right -->
  <div class="flex-1">
    <div
      class="
        grid
        grid-cols-[max-content_1fr]
        p-4
        gap-y-2
        rounded-lg
        shadow-lg
        font-bold
        text-center
        m-4
        text-white
        bg-blue-400
        stats-grid
      "
    >
      <h2 class="p-2">Base Stats</h2>
      <span></span>
      <!-- HP -->
      <div class="bg-red-500 p-4">
        <span>{{ "hp" | translate }}</span>
      </div>
      <div class="bg-red-500">
        <div
          class="bg-red-600 grid items-center place-items-end h-full"
          style.width="calc(100% * {{ getStatStr('hp') }}/255)"
        >
          <span class="mr-2">{{ getStatStr("hp") }}</span>
        </div>
      </div>
      <!-- Attack -->
      <div class="bg-orange-500 p-4">
        <span>{{ "attack" | translate }} </span>
      </div>
      <div class="bg-orange-500">
        <div
          class="bg-orange-600 grid items-center place-items-end h-full"
          style.width="calc(100% * {{ getStatStr('attack') }}/255)"
        >
          <span class="mr-2">{{ getStatStr("attack") }}</span>
        </div>
      </div>
      <!-- Defense -->
      <div class="bg-yellow-500 p-4">
        <span>{{ "defense" | translate }} </span>
      </div>
      <div class="bg-yellow-500">
        <div
          class="bg-yellow-600 grid items-center place-items-end h-full"
          style.width="calc(100% * {{ getStatStr('defense') }}/255)"
        >
          <span class="mr-2">{{ getStatStr("defense") }}</span>
        </div>
      </div>
      <!-- Special attack -->
      <div class="bg-blue-500 p-4">
        <span>{{ "special-attack" | translate }} </span>
      </div>
      <div class="bg-blue-500">
        <div
          class="bg-blue-600 grid items-center place-items-end h-full"
          style.width="calc(100% * {{ getStatStr('special-attack') }}/255)"
        >
          <span class="mr-2">{{ getStatStr("special-attack") }}</span>
        </div>
      </div>
      <!-- Special Defense -->
      <div class="bg-green-500 p-4">
        <span>{{ "special-defense" | translate }} </span>
      </div>
      <div class="bg-green-500">
        <div
          class="bg-green-600 grid items-center place-items-end h-full"
          style.width="calc(100% * {{ getStatStr('special-defense') }}/255)"
        >
          <span class="mr-2">{{ getStatStr("special-defense") }}</span>
        </div>
      </div>
      <!-- Speed -->
      <div class="bg-pink-500 p-4">
        <span>{{ "speed" | translate }} </span>
      </div>
      <div class="bg-pink-500">
        <div
          class="bg-pink-600 grid items-center place-items-end h-full"
          style.width="calc(100% * {{ getStatStr('speed') }}/255)"
        >
          <span class="mr-2">{{ getStatStr("speed") }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
