<nav
  [class.navbar-hide]="!this.showNavBar"
  class="
    navbar
    p-4
    w-full
    bg-white
    self-center
    inline-flex
    flex-wrap
    justify-center
    items-center
    gap-4
    z-10
    sticky
    top-0
  "
>
  <!-- Logo -->
  <a routerLink="/" class="flex items-center select-none gap-4">
    <img
      src="assets/img/favicon.svg"
      alt="Logo"
      class="w-10 h-10 object-contain"
    />
    <span class="text-2xl">PokeIndex</span>
  </a>
  <!-- Locale switch -->
  <app-locale-toggle></app-locale-toggle>
  <!-- Search Bar -->

  <mat-form-field
    appearance="standard"
    class="flex-1"
    (ngSubmit)="this.onSubmit()"
  >
    <mat-label>{{ "search.description" | translate }}</mat-label>
    <span matPrefix class="fa-solid fa-magnifying-glass">&nbsp;</span>
    <input
      #searchInput
      matInput
      type="text"
      (keypress)="this.onKeyPress($event)"
      aria-label="Pokemon Name or Number"
      [value]="searchBarValue"
      (input)="this.onInput($event)"
      (focus)="this.onFocus()"
      (keydown.enter)="this.onSubmit()"
    />
    <div matSuffix class="py-4">
      <span class="kbd">/</span>
    </div>
  </mat-form-field>
</nav>
