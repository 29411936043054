<button
  [class.checked]="isChecked"
  class="icon {{ pokemonType }} select-none"
  matTooltip="{{ pokemonType | translate | uppercase }}"
  (click)="this.isChecked = !this.isChecked"
>
  <img
    src="assets/img/poketype/{{ pokemonType }}.svg"
    alt="{{ pokemonType }} icon"
  />
</button>
