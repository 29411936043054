<!-- 2 boxes (Filter, Search) -->
<div class="search-layout block md:grid">
  <!-- Filters -->
  <div>
    <h1>{{ "search.filters" | translate }}</h1>
    <h2>{{ "search.filter.type" | translate }}:</h2>
    <div class="flex flex-wrap gap-4">
      <div *ngFor="let TName of this.pokemonTypesList">
        <app-poketype-checkbox
          pokemonType="{{ TName }}"
          [isChecked]="true"
          (click)="this.onToggleTypeFilter($event, TName)"
        ></app-poketype-checkbox>
      </div>
    </div>
  </div>

  <!-- Search Results -->
  <div class="p-2" *ngIf="this.localFilteredPokemon.length !== 0">
    <div class="text-lg" *ngIf="canShowSuggest()">
      Did you mean
      <a
        class="underline text-bold text-blue-500"
        (click)="onSuggestionsClick()"
      >
        {{
          this.pokedex.identifierToReadableName(this.localFilteredPokemon[0])
        }}
      </a>
      ?
    </div>
    <br />
    <div
      class="
        card-list-container
        self-center
        ml-0
        mr-0
        mt-0
        justify-center
        gap-4
      "
    >
      <app-pokecard
        *ngFor="
          let pokemonName of this.localFilteredPokemon.slice(0, this.maxShow)
        "
        [pokemonName]="pokemonName"
      ></app-pokecard>
    </div>
  </div>

  <!-- No cards found -->
  <!-- flex center horizontal and vertically -->
  <div
    class="grid grid-cols-1 p-2 gap-4 place-items-center justify-center"
    *ngIf="this.localFilteredPokemon.length === 0"
  >
    <h1 class="bold">Nothing found!</h1>
    <img
      (click)="randomPokemon()"
      alt="MISSINGNO"
      src="/assets/img/MISSINGNO.webp"
      class="object-contain h-[50vh] pixelated cursor-pointer"
    />
    <span>A wild MISSINGNO appeared!</span> <br />
  </div>
</div>
