<button
  (click)="onClick()"
  [style]="'color:' + this.cardColor + ';'"
  class="shadow-xl flex flex-col gap-0 p-2 w-[150px] h-[245px] {{
    this.cardColor
  }}-gradient card-root rounded-xl transform transition hover:scale-110 select-none overflow-hidden"
  matBadge="#{{ this.displayNumber || '?' }}"
  matBadgePosition="after"
  matBadgeColor="primary"
>
  <!-- Card Header (img) -->
  <div class="rounded-t-lg w-full card-sprite-container bg-white/30">
    <img
      class="card-sprite p-2 m-auto w-[90%] h-auto min-h-[50%] object-contain"
      fetchpriority="low"
      src="{{
        this.pokemon
          ? this.pokemon.sprites.front_default || '/assets/img/MISSINGNO.webp'
          : '/assets/img/MISSINGNO.webp'
      }}"
      alt="Photo
    of a {{ pokedex.identifierToReadableName(this.pokemonName) }}"
    />
  </div>

  <!-- Card Content num/name/etc -->
  <!-- {{this.cardColor}}-300  -->
  <div
    class="
      card-content
      grid
      rounded-b-lg
      place-items-center
      text-md
      h-full
      w-full
      bg-gray-500/30
    "
  >
    <div class="card-content-top p-1 h-full w-full text-center font-extrabold">
      {{ pokedex.identifierToReadableName(this.pokemonName) }}
    </div>

    <div class="card-content-bottom inline-flex flex-wrap gap-4">
      <app-poketype
        *ngFor="let TName of this.pokemon?.types"
        pokemonType="{{ TName.type.name }}"
      ></app-poketype>
    </div>
  </div>
</button>
