<!-- flex center horizontal and verical -->
<div class="flex justify-center items-center h-full">
  <!-- Aling vertical -->
  <div class="flex flex-col gap-4 place-items-center">
    <h1 class="bold">404 PAGE NOT FOUND</h1>
    <img
      routerLink="/pokemon/404"
      alt="Luxio"
      src="/assets/img/404.png"
      class="object-contain h-[50vh] cursor-pointer"
    />
    <span>{{ "404" | translate }}</span>
  </div>
</div>
